import React, { ReactNode } from 'react';

import { ClassName, GADataType } from '../../../../types';
import { TeamLinkTeam } from './TeamLink.types';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { NextLinkHelper } from '../../../../helpers/links/NextLinkHelper';
import { TeamPath } from '../../TeamPath';
import { TeamsPermissions } from '../../teamsConstants';

interface TeamLinkDefaultProps {
  team: TeamLinkTeam;
  className?: ClassName;
}

interface TeamLinkWithChildrenProps {
  text?: never;
  children: ReactNode;
}

interface TeamLinkWithTextProps {
  text: string;
  children?: never;
}

type TeamLinkProps = TeamLinkDefaultProps &
  (TeamLinkWithChildrenProps | TeamLinkWithTextProps) &
  GADataType;

function TeamLink({ team, className, text, children, dataGa }: TeamLinkProps) {
  const currentUser = useCurrentUser();

  const canReadLink = currentUser.hasPermissions(
    TeamsPermissions.READ_COMPANY_PROFILE_LINK
  );

  return (
    <NextLinkHelper
      className={className}
      href={canReadLink ? TeamPath.companyProfile(team.nanoId) : '#'}
      title={team.name}
      dataGa={dataGa}
    >
      {text || children}
    </NextLinkHelper>
  );
}

export default TeamLink;
