import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import toNumber from 'lodash/toNumber';
import map from 'lodash/map';
import compact from 'lodash/compact';

import { TaskNanoID } from '../../../../../../tasks/tasksTypes';
import { SmartContractID } from '../../../../../../smartContracts/smartContractsTypes';
import {
  UserClient,
  UserFullName,
  UserID,
  UserImageFile
} from '../../../../../../users/usersTypes';
import { GeneralLedgerID } from '../../../../../../generalLedgers/generalLedgersTypes';
import { CompanyEventTemplateID } from '../../../../../../companyEventTemplates/companyEventTemplatesTypes';

import {
  SmartContractShareIteration,
  SmartContractShareShareType,
  SmartContractShareShareTypes,
  SmartContractShareStatus,
  SmartContractShareStatuses,
  UpdateSmartContractSharesCacheKeys
} from '../../../../../smartContractSharesTypes';
import {
  CreateSmartContractShareFormData,
  CreateSmartContractShareFormShareCreatedStatus,
  CreateSmartContractShareFormView
} from '../../CreateSmartContractShareForm.types';

import { CREATE_SMART_CONTRACT_SHARE_QUERY } from '../../../../../queries/createSmartContractShare.query';
import {
  INVITE_USERS_TO_TASK_QUERY,
  InviteUsersToTaskQueryResponse
} from '../../../../../../tasks/queries/inviteUsersToTask.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCreateSmartContractShare } from '../../../../../hooks/useCreateSmartContractShare';
import { useInviteUsersToTask } from '../../../../../../tasks/hooks/useInviteUsersToTask';

import { TaskCache } from '../../../../../../tasks/TaskCache';

interface CreateSmartContractShareFormOptions {
  taskNanoId: TaskNanoID;
  iteration?: SmartContractShareIteration;
  smartContractId: SmartContractID;
  status: SmartContractShareStatus;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
  defaultUsers?: {
    id: UserID;
    client?: UserClient;
    generalLedgerId?: GeneralLedgerID;
    shareType?: SmartContractShareShareType;
    share?: number;
    createdStatus?: CreateSmartContractShareFormShareCreatedStatus;
    fullName: UserFullName;
    image?: {
      file: UserImageFile;
    };
    currentTeam?: {
      nanoId: string;
    };
  }[];
  afterCreate?: () => void;
  withoutEventIds?: CompanyEventTemplateID[];
}

type CheckCreatedUser = { success: UserID[]; failed: UserID[] };

const emptyUserIds: CheckCreatedUser = { success: [], failed: [] };

function useCreateSmartContractShareForm({
  taskNanoId,
  iteration,
  smartContractId,
  status,
  cacheKeys,
  defaultUsers,
  afterCreate,
  withoutEventIds = []
}: CreateSmartContractShareFormOptions) {
  const checkCreatedUser = useRef<CheckCreatedUser>(emptyUserIds);

  const isEmptyDefaultUsers = isEmpty(defaultUsers);

  const defaultValues = useMemo<CreateSmartContractShareFormData>(() => {
    const defaultIteration = iteration ? iteration.toString() : '';

    const inviteClientsToSc = status === SmartContractShareStatuses.CHECKED_IN;

    return isEmptyDefaultUsers
      ? {
          users: {
            clients: [],
            workers: []
          },
          inviteClientsToSc: inviteClientsToSc,
          inviteWorkersToSc: true,
          iteration: defaultIteration,
          withoutEventIds
        }
      : {
          users: {
            clients: map(
              defaultUsers.filter((user) => user.client),
              (user) => ({
                userId: user.id,
                share: user.share || 0,
                shareType: user.shareType,
                generalLedgerId: user.generalLedgerId,
                fullName: user.fullName,
                image: user.image,
                currentTeam: user.currentTeam
              })
            ),
            workers: map(
              defaultUsers.filter((user) => !user.client),
              (user) => ({
                userId: user.id,
                share: user.share || 0,
                shareType: user.shareType,
                generalLedgerId: user.generalLedgerId,
                fullName: user.fullName,
                image: user.image,
                currentTeam: user.currentTeam
              })
            )
          },
          inviteClientsToSc: inviteClientsToSc,
          inviteWorkersToSc: true,
          iteration: defaultIteration,
          withoutEventIds
        };
  }, [defaultUsers, isEmptyDefaultUsers, iteration, status, withoutEventIds]);

  const [formView, setFormView] = useState(
    isEmptyDefaultUsers
      ? CreateSmartContractShareFormView.SELECT_USERS
      : CreateSmartContractShareFormView.CHANGE_SHARE
  );

  const [disabledSubmit, setDisabledSubmit] = useState(isEmptyDefaultUsers);
  const [allUsersInviteOnlyToTask, setAllUsersInviteOnlyToTask] =
    useState(false);

  const {
    handleSubmitReactHookForm,
    control,
    resetForm,
    watch,
    setValue,
    getValues
  } = useReactHookForm<CreateSmartContractShareFormData>({
    defaultValues,
    isModalForm: true
  });

  const {
    createSmartContractShare,
    createSmartContractShareLoading,
    createSmartContractShareErrorMessage,
    createSmartContractShareReset
  } = useCreateSmartContractShare({
    query: CREATE_SMART_CONTRACT_SHARE_QUERY,
    cacheKeys
  });

  const {
    inviteUsersToTask,
    inviteUsersToTaskErrorMessage,
    inviteUsersToTaskLoading,
    inviteUsersToTaskReset
  } = useInviteUsersToTask<InviteUsersToTaskQueryResponse>({
    query: INVITE_USERS_TO_TASK_QUERY,
    cacheKeys: [TaskCache.taskMembersInvitesCacheKey(taskNanoId)]
  });

  const handleResetCreateSmartContractShareForm = useCallback<
    () => void
  >(() => {
    checkCreatedUser.current.failed = [];
    checkCreatedUser.current.success = [];
    resetForm(defaultValues);
    setFormView(
      isEmptyDefaultUsers
        ? CreateSmartContractShareFormView.SELECT_USERS
        : CreateSmartContractShareFormView.CHANGE_SHARE
    );
    setDisabledSubmit(isEmptyDefaultUsers);
    createSmartContractShareReset();
    inviteUsersToTaskReset();
  }, [
    createSmartContractShareReset,
    defaultValues,
    inviteUsersToTaskReset,
    isEmptyDefaultUsers,
    resetForm
  ]);

  const goToChangeShareView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.CHANGE_SHARE);
  }, []);

  const goToSelectUsersView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.SELECT_USERS);
  }, []);

  useEffect(() => {
    const subscription = watch((data, { type }) => {
      if (formView === CreateSmartContractShareFormView.SELECT_USERS) {
        const emptyData =
          isEmpty(data.users.clients) && isEmpty(data.users.workers);

        disabledSubmit !== emptyData && setDisabledSubmit(emptyData);
      }

      if (createSmartContractShareErrorMessage && type === 'change') {
        createSmartContractShareReset();
      }
    });
    return () => subscription.unsubscribe();
  }, [
    createSmartContractShareErrorMessage,
    createSmartContractShareReset,
    disabledSubmit,
    formView,
    watch
  ]);

  useEffect(() => {
    const subscription = watch((data) => {
      if (
        formView === CreateSmartContractShareFormView.SELECT_USERS &&
        status === SmartContractShareStatuses.INVITED
      ) {
        const {
          users: { workers, clients },
          inviteClientsToSc,
          inviteWorkersToSc
        } = data;

        const isWorkersInviteOnly = !isEmpty(workers) && !inviteWorkersToSc;
        const isClientsInviteOnly = !isEmpty(clients) && !inviteClientsToSc;

        setAllUsersInviteOnlyToTask(
          (!data.inviteClientsToSc && !data.inviteWorkersToSc) ||
            (isEmpty(workers) && isClientsInviteOnly) ||
            (isWorkersInviteOnly && isEmpty(clients))
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [formView, isEmptyDefaultUsers, status, watch]);

  const handleSubmitForm = handleSubmitReactHookForm({
    onSubmit: async (data: CreateSmartContractShareFormData) => {
      const {
        users: { clients, workers },
        iteration,
        withoutEventIds,
        inviteClientsToSc,
        inviteWorkersToSc
      } = data;

      const allUsersToCreate = compact(
        concat(
          inviteClientsToSc ? clients : null,
          inviteWorkersToSc ? workers : null
        )
      );

      const allUsersToInvite = compact(
        concat(
          inviteClientsToSc ? null : clients,
          inviteWorkersToSc ? null : workers
        )
      );

      const userIds = allUsersToInvite.map((user) => user?.userId);

      const invitingToTask = !isEmpty(userIds)
        ? inviteUsersToTask({
            uuid: taskNanoId,
            userIds
          })
        : Promise.resolve();

      const usersForCreating = isEmpty(checkCreatedUser.current.success)
        ? allUsersToCreate
        : allUsersToCreate.filter(
            (user) => !checkCreatedUser.current.success.includes(user.userId)
          );

      const creatingShares = usersForCreating.map((user) =>
        createSmartContractShare({
          generalLedgerId: user.generalLedgerId,
          iteration: toNumber(iteration) || 0,
          finalAt: !iteration ? new Date().toISOString() : undefined,
          shareType: user.shareType as SmartContractShareShareTypes,
          share: toNumber(user.share),
          smartContractId: smartContractId,
          status,
          withoutEventIds: withoutEventIds
        })
          .then(
            (result) => {
              const createdUserId =
                result?.createSmartContractShare?.record?.id;

              if (createdUserId) {
                checkCreatedUser.current.success = [
                  ...checkCreatedUser.current.success,
                  user.userId
                ];
              }

              return result;
            },
            (error) => {
              checkCreatedUser.current.failed = [
                ...checkCreatedUser.current.failed,
                user.userId
              ];

              throw error;
            }
          )
          .finally(() => {
            const workersWithCreatedStatus = map(workers, (worker) => ({
              ...worker,
              createdStatus: checkCreatedUser.current.success.includes(
                worker.userId
              )
                ? CreateSmartContractShareFormShareCreatedStatus.SUCCESS
                : CreateSmartContractShareFormShareCreatedStatus.FAILED
            }));

            const clientsWithCreatedStatus = map(clients, (client) => ({
              ...client,
              createdStatus: checkCreatedUser.current.success.includes(
                client.userId
              )
                ? CreateSmartContractShareFormShareCreatedStatus.SUCCESS
                : CreateSmartContractShareFormShareCreatedStatus.FAILED
            }));

            if (!isEmpty(workersWithCreatedStatus)) {
              setValue('users.workers', workersWithCreatedStatus);
            }

            if (!isEmpty(clientsWithCreatedStatus)) {
              setValue('users.clients', clientsWithCreatedStatus);
            }
          })
      );

      return Promise.all([invitingToTask, ...creatingShares]).then(() => {
        afterCreate?.();
      });
    },
    dirtyFieldsOnly: false
  });

  return {
    control,
    createSmartContractShare,
    createSmartContractShareLoading,
    inviteUsersToTaskLoading,
    createSmartContractShareErrorMessage,
    inviteUsersToTaskErrorMessage,
    createSmartContractShareReset,
    handleResetCreateSmartContractShareForm,
    handleSubmit:
      formView === CreateSmartContractShareFormView.CHANGE_SHARE ||
      allUsersInviteOnlyToTask
        ? handleSubmitForm
        : goToChangeShareView,
    setValue,
    disabledSubmit,
    formView,
    goToChangeShareView,
    goToSelectUsersView,
    oneFormView: !isEmptyDefaultUsers || allUsersInviteOnlyToTask,
    withoutEditUsers: !isEmptyDefaultUsers,
    getValues
  };
}

export default useCreateSmartContractShareForm;
